/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require('./bootstrap');
// require('datatables.net-bs4');
// require('nestable2');
window.Swal = require('sweetalert2');
window.toastr = require('toastr');

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'


window.Alpine = Alpine
Alpine.plugin(collapse)
Alpine.start()


/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}


import Animasection from './animasection.js';
Animasection.observeAll()

// You might need to stop observing in some applications
// Animasection.unobserveAll()




// window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

// Layouts


// Vue.component('translation-input', require('./components/TranslationInput.vue').default);
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


// const app = new Vue({
//     el: '#app',
//
//     data: function () {
//         return {
//             showAdvancedOptions: false,
//         }
//     },
//
//     methods: {
//         submit: function(event) {
//             event.target.form.submit();
//         },
//
//         toggleAdvancedOptions(event) {
//             event.preventDefault();
//             this.showAdvancedOptions = !this.showAdvancedOptions;
//         }
//     }
// });
